import React, { Component } from "react";
import { withApollo } from "react-apollo";
import PropTypes from "prop-types";
import { Link } from "react-router";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { map, filter } from "lodash";
import { Helmet } from "react-helmet";
import BrowseLunchThumb from "../../components/lunches/BrowseLunchThumb";
import CreateLunch from "../../components/create/CreateLunch";
import MailingListPopup from "../../components/general/MailingListPopup";

class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createLunch: false,
    };
  }
  componentDidMount() {
    window.twttr = (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f) {
        t._e.push(f);
      };
      return t;
    })(document, "script", "twitter-wjs");
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "566204480216246",
        status: true,
        xfbml: true,
        cookie: true,
        version: "v18.0",
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  async componentDidMount() {
    const googletag = window.googletag;
    if (googletag) {
      googletag.cmd.push(function() {  
        googletag.display('div-gpt-ad-1677698614152-0');
        googletag.display('div-gpt-ad-1677698803928-0');
        googletag.display('div-gpt-ad-1677699722358-0');
        googletag.display('div-gpt-ad-1677699928463-0');
      });
    }
  }

  render() {
    const { location, user, data } = this.props;
    let categories = filter(
      data.feed,
      (feedCategory) =>
        feedCategory.lunches != null && feedCategory.lunches.length > 0
    );
    const feed = map(categories, (feedCategory) => {
      const lunchThumbes = map(feedCategory.lunches, (lunch) => {
        if (!lunch.user) {
          lunch.user = {
            image: null,
          };
        }
        return (
          <BrowseLunchThumb
            lunch={lunch}
            key={lunch.id}
            location={location}
            loggedIn={Boolean(user.id)}
          />
        );
      });

      return (
        <div className="feed-categories" key={feedCategory.name}>
          {this.state.createLunch ? (
            <CreateLunch
              userId={user.id}
              closePopup={() => this.setState({ createLunch: false })}
            />
          ) : (
            ""
          )}
          <div className="my-categories-title">
            <div className="my-categories-title-name">
              <h2>{feedCategory.name}</h2>
            </div>
            <div className="my-categories-seeall">
              <Link
                to={{
                  pathname: "/browse",
                  search: `?filter=${feedCategory.filterKeyword}`,
                }}
              >
                See all
              </Link>
            </div>
          </div>
          <div className="my-categories">
            <div className="my-categories-header">
              <div className="category-ideas">
                <div className="ideas-lunches">{lunchThumbes}</div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    // const pub =
    // <a className="pub-banner-laptop-link" href="https://www.lelycee.org/learning/maternelle/la-petite-ecole?utm_source=Feed&utm_medium=Banners&utm_campaign=Teuko+2022-2023+-+La+petite+ecole" key="pub link" target="_blank" rel="noopener noreferrer">
    //   <div className="pub-banner-advertisement-label">Advertisement</div>
    //   <div key="pub-smartphone" className="pub-banner-smartphone" alt="Ad Feed" style={{ backgroundImage: 'url(/images/Banner_Ad_Feed_LFSF_Jan-Feb23_300x300.jpg)' }}></div>
    //   <div key="pub" className="pub-banner-laptop" alt="Ad Feed" style={{ backgroundImage: 'url(/images/Banner_Ad_Feed_LFSF_Jan-Feb23_728x90.jpg)' }}></div>
    // </a>; 

    //  const pub =
    // <a className="pub-banner-laptop-link" href="https://amzn.to/3YRZEgN" key="pub link" target="_blank" rel="noopener noreferrer">
    //   <div className="pub-banner-advertisement-label">Advertisement</div>
    //   <div key="pub-smartphone" className="pub-banner-smartphone" alt="Ad Feed Teuko Kids Lunchbox Bento School Lunch Accessories Shopping" style={{ backgroundImage: 'url(/images/Banner_Ad_Feed_Teuko_2023Mar_Teuko-Boutique-StPatricksDay-300x300.gif)' }}></div>
    //   <div key="pub" className="pub-banner-laptop" alt="Ad Feed Teuko Kids Lunchbox Bento School Lunch Accessories Shopping" style={{ backgroundImage: 'url(/images/Banner_Ad_Feed_Teuko_2023Mar_Teuko-Boutique-StPatricksDay-728x90.gif)' }}></div>
    // </a>;

      const pub =
      <div key="pub-banner-advertisement-middle-page" className="pub-banner-advertisement-middle-page">
        <div className="pub-banner-advertisement-label">-</div>
        <div key="pub" className="pub-banner-laptop" alt="Ad Feed Teuko Kids Lunchbox Bento School Lunch">
          {/* <!-- /22874318077/Teuko-Feed-Laptop_AdUnit_2_Middle_Page-Leaderboard-728x90 --> */}
          <div id='div-gpt-ad-1677699722358-0' style={{minWidth: "728px", minHeight: "90px"}} />
        </div>
        <div key="pub-smartphone" className="pub-banner-smartphone" alt="Ad Feed Teuko Kids Lunchbox Bento School Lunch" >
          {/* <!-- /22874318077/Teuko-Feed-Smartphone_AdUnit_2_Middle_Page-Leaderboard-300x250 --> */}
          <div id='div-gpt-ad-1677699928463-0' style={{minWidth: "300px", minHeight: "250px"}} />
        </div>
      </div>;


    //  const pub =
    //  <div className="pub-amazn-container" key="pub-amazn-container">
    //    <div className="pub-banner-advertisement-label">Advertisement</div>
    //    <iframe className="pub-amazn-laptop" key="pub-amazn-laptop" src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemain&banner=17T0MQR5WKGJCZNRHCG2&f=ifr&linkID=f76e4bc310f80a6239d3cba72fe3ae26&t=teuko-20&tracking_id=teuko-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{"border": "none"}} frameBorder="0"></iframe>
    //    <iframe className="pub-amazn-smartphone" key="pub-amazn-smartphone"src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=primemain&banner=028WNSXDMC6H5YDNCB82&f=ifr&linkID=b6be4147dd2997501faa44ce32b8e24e&t=teuko-20&tracking_id=teuko-20" width="300" height="250" scrolling="no" border="0" marginWidth="0" style={{"border": "none"}} frameBorder="0"></iframe>
    //  </div>
    //  ;

    
    const emplacementDeLaPub = 3; // 0 = 1er, 1 = entre 1er et 2ème...
    if (user.id !== 2277) {
      feed.splice(emplacementDeLaPub, 0, pub);
    }

    if (feed.length === 1) {
      const recoMsg = user && user.id > 0 ?
        "Building your personnalized recommendations..." 
        : "Loading feed...";
      feed.splice(1, 0, <div key="personnalized_reco">{recoMsg}</div>);
    }

    return (
      <div className="browse-page">
        <Helmet>
          <title>Teuko Lunchbox Community - Feed of Kids Bento Lunch Ideas</title>
          <script type='application/ld+json' dangerouslySetInnerHTML={ { __html: `
            {
              "@context": "https://schema.org",
              "@type": "ItemList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "url": "https://www.teuko.com/l/0940b1bf"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "url": "https://www.teuko.com/l/92e7f6d7"
                },
                {
                  "@type": "ListItem",
                  "position": 3,
                  "url": "https://www.teuko.com/l/1ad2d1a7"
                },
                {
                  "@type": "ListItem",
                  "position": 4,
                  "url": "https://www.teuko.com/l/bf36e253"
                },
                {
                  "@type": "ListItem",
                  "position": 5,
                  "url": "https://www.teuko.com/l/c4fed4f3"
                },
                {
                  "@type": "ListItem",
                  "position": 6,
                  "url": "https://www.teuko.com/l/5144a0af"
                },
                {
                  "@type": "ListItem",
                  "position": 7,
                  "url": "https://www.teuko.com/l/2e76f4ed"
                },
                {
                  "@type": "ListItem",
                  "position": 8,
                  "url": "https://www.teuko.com/l/9097aa8b"
                },
                {
                  "@type": "ListItem",
                  "position": 9,
                  "url": "https://www.teuko.com/l/956d265d"
                },
                {
                  "@type": "ListItem",
                  "position": 10,
                  "url": "https://www.teuko.com/l/6aab8926"
                },
                {
                  "@type": "ListItem",
                  "position": 11,
                  "url": "https://www.teuko.com/l/e015ea75"
                },
                {
                  "@type": "ListItem",
                  "position": 12,
                  "url": "https://www.teuko.com/l/4eb12496"
                },
                {
                  "@type": "ListItem",
                  "position": 13,
                  "url": "https://www.teuko.com/l/f2b287eb"
                },
                {
                  "@type": "ListItem",
                  "position": 14,
                  "url": "https://www.teuko.com/l/50349492"
                },
                {
                  "@type": "ListItem",
                  "position": 15,
                  "url": "https://www.teuko.com/l/16cd697c"
                },
                {
                  "@type": "ListItem",
                  "position": 16,
                  "url": "https://www.teuko.com/l/488d6d54"
                },
                {
                  "@type": "ListItem",
                  "position": 17,
                  "url": "https://www.teuko.com/l/6fedd7f0"
                },
                {
                  "@type": "ListItem",
                  "position": 18,
                  "url": "https://www.teuko.com/l/d46d5b8f"
                },
                {
                  "@type": "ListItem",
                  "position": 19,
                  "url": "https://www.teuko.com/l/578a0cc1"
                },
                {
                  "@type": "ListItem",
                  "position": 20,
                  "url": "https://www.teuko.com/l/8d663fc5"
                }]
            }
            `}} />
        </Helmet>
        <div className="in-app-container">


        {/* <div className="contest-announcement">
             { user.id !== 0 ? (
                <a href="/challenges" onClick={() => this.setState({ createLunch: true })}>
                  <h3 className="contest-title">
                  Join the Christmas Challenge & win a $200 Lunch Prep Bundle!
                </h3></a>
              ) : (
                <a href="#" onClick={() => browserHistory.push('/signup')}>
                  <h3 className="contest-title">
                  Join the Christmas Challenge & win a $200 Lunch Prep Bundle!
                </h3></a>
              )}  
        </div> */}

            {/* <p className="contest-text">
            <a className="contest-title-link" href="/giveaway" >GIVEAWAY! The Teuko Advent Calendar is back. Enter for your chance to win! {'>'}</a>
            </p>
            </div> */}

         {/* <div className="contest-announcement">
            <p className="contest-text">
            <a className="contest-title-link" href="/challenges" >GIVEAWAY with the Christmas Challenge! <span className="full-menu-option-cta-button-message">Enter Now</span> </a>
            </p>
          </div>  */}

          <div className="browse-page-header">
            <h1>Lunchbox Ideas</h1>
          </div>

          <div className="my-feed-features">
            {user.id !== 0 ? (
              ""
            ) : (
              <MailingListPopup delayInMilliseconds={15000} client={this.props.client}/>
            )}
            <div className="my-categories">
              <div className="my-categories-header">
                <div className="category-blog-items">
                  <div className="feed-explore-grid">

                  <div
                      className="infocard-blog"
                      data-qa="explore_teuko_lunch_ideas"
                    >
                      <div className="infocard-blog-content">
                        <a href="/search?theme=Valentine%27s%20Day">
                          <img
                            src="/images/teuko lunchbox feed Valentine Day 2025.jpg"
                            className="infocard-blog-image"
                            alt="Teuko Kids Bento Lunch Box Ideas Valentine Day 2025"
                          />
                        </a>
                      </div>
                      <div className="infocard-header-landing">
                        <a href="/search?theme=Valentine%27s%20Day">
                          <h3>Valentine's Day {'>'}</h3>
                        </a>
                      </div>
                  </div>

                    <div className="infocard-blog" data-qa="explore_teuko_lunch_ideas">
                    <div className="infocard-blog-content">
                      <a href="/search?theme=Winter">
                    <img
                      src="/images/teuko lunchbox feed Winter 2024.jpg"
                      className="infocard-blog-image"
                      alt="Teuko Kids Bento Lunch Box Ideas Winter"
                      /></a>
                    </div>
                    <div className="infocard-header-landing">
                      <a href="/search?theme=Winter"><h3>Winter {'>'}</h3></a>
                    </div>
                  </div>

                  <div
                      className="infocard-blog"
                      data-qa="explore_teuko_lunch_ideas"
                    >
                      <div className="infocard-blog-content">
                        <a href="/search?theme=Chinese%20New%20Year">
                          <img
                            src="/images/teuko lunchbox feed Chinese New Year 2025.jpg"
                            className="infocard-blog-image"
                            alt="Teuko Lunch Box Ideas Chinese New Year 2025"
                          />
                        </a>
                      </div>
                      <div className="infocard-header-landing">
                        <a href="/search?theme=Chinese%20New%20Year">
                          <h3>Chinese New Year {'>'}</h3>
                        </a>
                      </div>
                    </div>

                    <div
                      className="infocard-blog"
                      data-qa="explore_teuko_lunch_ideas"
                    >
                      <div className="infocard-blog-content">
                        <a href="/search?theme=Hot%20Lunch">
                          <img
                            src="/images/teuko lunchbox feed hot lunch 2024.jpg"
                            className="infocard-blog-image"
                            alt="Teuko Kids Bento Lunch Box Ideas hot warm lunch 2024"
                          />
                        </a>
                      </div>
                      <div className="infocard-header-landing">
                        <a href="/search?theme=Hot%20Lunch">
                          <h3>Hot Lunch {'>'}</h3>
                        </a>
                      </div>
                  </div> 

                    <div
                      className="infocard-blog"
                      data-qa="explore_teuko_lunch_ideas"
                    >
                      <div className="infocard-blog-content">
                        <a href="/search">
                          <img
                            src="/images/landing_explore_searchfoods.jpg"
                            className="infocard-blog-image"
                            alt="Search Teuko Lunch Box Ideas"
                          />
                        </a>
                      </div>
                      <div className="infocard-header-landing">
                        <a href="/search">
                          <h3>More {'>'}</h3>
                        </a>
                      </div>
                    </div>
                    

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="blog-ad-banner">
            <div className="search-title-header-ad-laptop_leaderboard">
              {/* <!-- /22874318077/Teuko-Feed-Laptop_AdUnit_1_Top_Page-Leaderboard-728x90 --> */}
              <div id='div-gpt-ad-1677698614152-0' style={{minWidth: "728px", minHeight: "90px"}} />
            </div>
            <div className="search-title-header-ad-smartphone_leaderboard">
              {/* <!-- /22874318077/Teuko-Feed-Smartphone_AdUnit_1_Top_Page-Leaderboard-320x50 --> */}
              <div id='div-gpt-ad-1677698803928-0' style={{minWidth: "320px", minHeight: "50px"}} />
            </div>
          </div>

          {feed}

        
          { user.id !== 0 ? (
            <div/>
          ):(      
          <div className="xoegz02">
            <div className="ab8q">
              <div className="x78zum5">

              <div className="xjbqb8wlogo">
                <img
                  src="/images/teuko logo no background square 400x400.png"
                  className="xjbqb8wlogoimage"
                  alt="Teuko lunchbox community kids bento lunch ideas logo no background"
                />
              </div>
              <div className="acps">
                <div className="x9f619">
                  <div className="aacl">
                    Log into Teuko
                  </div>
                </div>
                <div className="aadf">
                  {/* Log in to discover and keep lunchbox ideas you'll love. */}
                  Log in to discover more lunchbox ideas and keep them in your account.
                </div>
              </div>
              <div>
                <Link to="/login" className="full-menu-option-outheader-login-bannerbottom">
                  Log In
                </Link>
              </div>
              <div>
                <Link to="/signup" className="full-menu-option-outheader-signup-bannerbottom">
                  Sign Up
                </Link>
              </div>
               
            </div> 
          </div>
        </div>
        )}
        

        </div>
        {/* {!loading && user.id && !user.onboard_age ? ReactDOM.createPortal(<OnboardAge id={user.id} close={this.close} />, document.getElementById('popup-mount')) : ''} */}
      </div>
    );
  }
}

Feed.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object,
  loggedIn: PropTypes.bool,
};

export const feedQuery = gql`
  query feed($rowLimit: Int!) {
    feed(rowLimit: $rowLimit) {
      name
      filterKeyword
      lunches {
        id
        slug
        liked
        saved
        user_id
        image
        likes
        creation_date
        user {
          id
          user_name
          image
        }
        foods {
          id
          name
        }
      }
    }
  }
`;

export default withApollo(graphql(feedQuery, {
  options: (props) => ({
    variables: { rowLimit: 10 },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  }),
})(Feed));
